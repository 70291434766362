// ! DEPRECATED: Si lo usas pagas los kebabs del jueves

$extra-small: '(max-width : 768px)';
$small: '(min-width : 768px) and (max-width : 1024px)';
$phone: '(max-width : 1200px)';
$tablet: '(min-width : 1024px) and (max-width : 1200px)';
$laptop: '(min-width : 1200px)  and (max-width : 1440px)';
$computerMin: '(min-width : 1440px)  and (max-width : 1640px)';
$computer: '(min-width : 1640px)';

// * New Design System

$breakpoints: (
  xs: 320px,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  xxl: 1640px,
  xxxl: 1920px,
);

@mixin media($from: false, $until: false, $and: false, $media-type: all) {
  $min-width: 0;
  $max-width: 0;
  $query: '';

  @if $from {
    @if type-of($from) == number {
      $min-width: $from;
    } @else {
      $min-width: map-get($breakpoints, $from);
    }
  }

  @if $until {
    @if type-of($until) == number {
      $max-width: $until - 1px;
    } @else {
      $max-width: map-get($breakpoints, $until) - 1px;
    }
  }

  @if $min-width != 0 {
    $query: '#{$query} and (min-width: #{$min-width})';
  }
  @if $max-width != 0 {
    $query: '#{$query} and (max-width: #{$max-width})';
  }
  @if $and {
    $query: '#{$query} and (#{$and})';
  }

  @if ($media-type == 'all' and $query != '') {
    $media-type: '';
    $query: str-slice(unquote($query), 6);
  }

  @media #{$media-type + $query} {
    @content;
  }
}

@mixin xs-sm {
  @include media($from: 'xs', $until: 'sm') {
    @content;
  }
}
@mixin sm-md {
  @include media($from: 'sm', $until: 'md') {
    @content;
  }
}
@mixin md-lg {
  @include media($from: 'md', $until: 'lg') {
    @content;
  }
}
@mixin lg-xl {
  @include media($from: 'lg', $until: 'xl') {
    @content;
  }
}
@mixin xl-xxl {
  @include media($from: 'xl', $until: 'xxl') {
    @content;
  }
}
@mixin xxl-xxxl {
  @include media($from: 'xxl', $until: 'xxxl') {
    @content;
  }
}
