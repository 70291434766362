.swal2-modal {
  padding: var(--space-5) !important;
  border-radius: var(--border-radius-3) !important;
  background-color: var(--bg-2) !important;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12) !important;
}
.swal2-header {
  padding: 0 var(--space-0) !important;
  .swal2-title {
    width: 100%;
    margin: 0 0 var(--space-3);
    color: var(--color-text-500);
    font-size: var(--font-size-6);
    font-weight: bolder;
    text-align: left;
    text-transform: uppercase;
  }
}
.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba(29, 32, 37, 0.95);
  // background: rgba(0, 0, 0, 0.8) !important;
}
.swal2-popup {
  border: 2px solid var(--bg-3) !important;
  border-radius: var(--border-radius-3) !important;
  &.swal2-toast {
    // border: 2px solid var(--color-primary-500) !important;
    border-radius: var(--border-radius-3) !important;
  }
}
.swal2-content {
  padding: 0 var(--space-0) !important;
  .swal2-file,
  .swal2-input,
  .swal2-textarea {
    border-color: var(--bg-4);
    border: none;
    background: var(--bg-1);
    box-shadow: none !important;
    font-size: var(--font-size-4);
    border-radius: var(--border-radius-3);
    color: var(--color-text-600);
    margin: 0;
    &::placeholder {
      color: var(--bg-4);
      opacity: 1;
    }
    &:focus {
      border: none;
      outline: 0;
      box-shadow: none !important;
    }
  }
}
.swal2-actions {
  flex-flow: row-reverse;
  justify-content: flex-start !important;
  button {
    margin-left: var(--space-2);
    &.swal2-styled {
      border: 0;
      border-radius: var(--border-radius-3);
      padding: var(--space-1) var(--space-4);
      margin-left: var(--space-2);
      font-size: var(--font-size-3);
      border: none !important;
      &.swal2-confirm {
        color: var(--bg-0);
        background-color: var(--color-primary-500);
      }
      &.swal2-cancel {
        background-color: var(--bg-0);
        color: var(--bg-0);
      }
    }
  }
}
