@import 'media-queries';

@import 'flex-box-mixins.scss';

$transition-duration: 50ms;
$transition-timing-function: ease-in-out;

ngx-smart-modal {
  // z-index: 1000000000;
  position: relative;
  &::-webkit-scrollbar {
    width: 0px !important;
  }
  .nsm-dialog {
    transition: 0ms !important;
  }
  .overlay {
    display: none;
    transition: background-color 100ms !important;
  }
  .nsm-overlay-open {
    display: block;
    background: rgba(0, 0, 0, 0.8) !important;
    backdrop-filter: blur(10px);

    &::-webkit-scrollbar {
      width: 0px !important;
    }
  }

  .nsm-full {
    width: 100% !important;
    max-width: 100% !important;
  }

  .nsm-large {
    width: 1200px !important;
    max-width: 1200px !important;
  }

  .nsm-transparent {
    width: 100% !important;
    max-width: 100% !important;
    .nsm-content {
      margin: 0;
      background-color: transparent !important;
      box-shadow: none !important;
      border: none !important;
    }
  }

  .nsm-dialog {
    @include flex(flex, row, center, center);
    max-width: fit-content;
    min-width: 480px;
    min-height: 100vh;
    transition: none;
    &.nsm-dialog-open {
      animation: appear_bottom 200ms ease both;
    }
    .nsm-content {
      padding: 0;
      border-radius: var(--border-radius-4);
      background-color: var(--bg-2);
      box-shadow: var(--panel-shadow);
    }
    .nsm-dialog-btn-close {
      display: none;
    }
  }

  .nsm-full-content {
    max-width: 100vw;
    .nsm-dialog {
      max-width: 100vw;
      min-height: 90vh;
    }
    .nsm-content {
      max-height: 90vh;
      overflow: hidden;
      width: 100%;
    }
  }

  @mixin xs-smSmartModal {
    .nsm-dialog {
      min-width: 100vw;
      width: 100vw;
      margin: 0;
      .nsm-content {
        margin: 0;
        border-radius: 0;
      }
    }
  }

  @include xs-sm {
    @include xs-smSmartModal;
  }
  @include sm-md {
    @include xs-smSmartModal;
  }
  @include md-lg {
    @include xs-smSmartModal;
  }
}
