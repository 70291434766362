@import 'flex-box-mixins.scss';

.cdk-overlay-container {
  z-index: 100000000000 !important;
}

.dropdown-menu {
  min-width: 200px;
  border-radius: var(--border-radius-2);
  box-shadow: 0 8px 16px #0000003d;
  color: var(--color-text-600);
  padding: 0;
  margin: 0;
  background: var(--bg-1);
  border: 1px solid var(--bg-3);
  overflow: hidden;
  li {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    border-bottom: 1px solid var(--bg-3);
    border-radius: 0;
    &.disabled {
      cursor: default;
      pointer-events: none;
    }
    :not(.passive) {
      cursor: pointer;
      &:hover {
        background-color: var(--bg-4);
        a {
          color: var(--color-text-500);
          b {
            color: var(--color-text-400);
          }
        }
        i {
          color: var(--color-text-500) !important;
        }
      }
    }
    &:last-child {
      border-bottom: none;
    }
    i {
      margin-right: var(--space-1);
    }
    img {
      margin-right: var(--space-1);
      height: 18px;
    }
    a {
      min-height: 32px;
      padding: 6px 8px;
      color: var(--color-text-600);
      font-weight: 500;
      font-size: var(--font-size-2);
      text-decoration: none !important;
      width: 100%;
      @include flex(flex, row, flex-start, center);
      border-radius: 0;
      dx-ui-switch {
        margin-right: var(--space-2);
      }
      b {
        color: var(--color-text-600);
        text-transform: capitalize;
      }
      &.hasSubMenu {
        &::before {
          display: none;
        }
        &::after {
          content: '\f0da';
          font-family: 'Font Awesome 6 Pro';
          font-weight: 900;
          margin-left: var(--space-1);
        }
      }
    }
    .avatar {
      width: 25px;
      object-fit: cover;
      object-position: top;
      margin-right: var(--space-1);
    }
    &.divider {
      // padding: 0;
      // overflow: hidden;
      // margin: 4px;
      // border-bottom: 1px solid rgba(255, 255, 255, 0.06);
      // border-radius: 0;
      // background: transparent;
      // min-height: 0;
      display: none;
    }
    &.title {
      cursor: default;
      pointer-events: none;
      a {
        font-weight: bold;
        color: var(--color-text-400);
        font-size: var(--font-size-1);
      }
    }
    &.disabled {
      &:hover {
        background-color: transparent;
        cursor: default;
        a {
          cursor: default;
          color: var(--color-text-600);
        }
      }
    }
  }
}
