.ngx-datatable {
  box-shadow: none;
  background: var(--bg-0);
  color: var(--color-text-700);
  font-size: 14px;
  border-radius: var(--border-radius-2);

  &.multi-click-selection {
    .datatable-body-row {
      cursor: pointer;
    }
  }

  .datatable-header {
    display: flex !important;
    align-items: center !important;
    background: var(--bg-2);
    font-family: var(--font-family-1);
    color: var(--color-text-700);
    height: 50px !important;
    width: 100%;
    .datatable-header-cell {
      font-size: 13px;
      color: var(--color-text-500);
      text-align: left;
      padding: 1rem 1.1rem;
      padding: var(--space-1) var(--space-2);
      .datatable-header-cell-label {
        line-height: 24px;
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        color: var(--color-text-600);
      }
      .sort-btn {
        margin-left: var(--space-0);
      }
    }
    .empty-header {
      opacity: 0;
    }
  }

  .datatable-body {
    .empty-row {
      height: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: var(--font-size-3);
    }

    .datatable-body-row {
      background-color: var(--bg-1);
      border-bottom: 1px solid;
      border-color: var(--bg-2);

      &:hover {
        background-color: var(--bg-3);
        color: var(--color-text-500);
        transition-property: background;
        transition-duration: 0.15s;
      }

      .datatable-body-cell {
        text-align: left;
        padding: var(--space-1) var(--space-2);
        vertical-align: top;
        display: flex;
        align-items: center;
        line-height: initial;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-height: 41px;
        &-label {
          width: 100%;
        }
      }

      &.active {
        background-color: rgba(37, 150, 120, 0.1);
        color: var(--color-text-500);
        border-color: transparent;
        &:hover {
          background-color: rgba(0, 230, 169, 0.2) !important;
          color: var(--color-text-500);
        }
      }
    }
  }

  .datatable-footer {
    background: var(--bg-2);
    color: var(--color-text-700);
    margin-top: -1px;
    height: 100%;
    .page-count {
      line-height: 50px;
      height: 50px;
      padding: 0 1.2rem;
    }

    .datatable-pager {
      margin: 0 10px;
      vertical-align: top;

      ul {
        li {
          margin: 10px 0px;
          color: var(--color-text-500);
          &:not(.disabled) {
            &.active a,
            &:hover a {
              background-color: var(--bg-4);
              color: var(--color-primary-500);
              font-weight: bold;
            }
          }
        }
      }

      a {
        height: 22px;
        min-width: 24px;
        line-height: 22px;
        padding: 0;
        border-radius: 3px;
        margin: 0 3px;
        text-align: center;
        vertical-align: top;
        text-decoration: none;
        vertical-align: bottom;
        color: var(--color-text-500);
      }

      .datatable-icon-left,
      .datatable-icon-skip,
      .datatable-icon-right,
      .datatable-icon-prev {
        font-size: 18px;
        line-height: 27px;
        padding: 0 3px;
      }
    }
  }

  .datatable-summary-row {
    .datatable-body-row {
      background-color: var(--bg-4);

      &:hover {
        background-color: var(--bg-4);
      }

      .datatable-body-cell {
        font-weight: bold;
      }
    }
  }
}

/**
 * Checkboxes ngx-datatable
**/
.datatable-checkbox {
  position: relative;
  margin: 0;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  padding: 0;

  input[type='checkbox'] {
    position: relative;
    margin: 0 1rem 0 0;
    cursor: pointer;
    outline: none;

    &:before {
      transition: all 0.15s ease-in-out;
      content: '';
      position: absolute;
      top: -1px;
      left: -1px;
      z-index: 1;
      width: var(--space-3);
      height: var(--space-3);
      border: 2px solid;
      border-color: var(--bg-3);
      border-radius: var(--border-radius-0);
    }

    &:checked:before {
      transform: rotate(-45deg);
      height: 0.45rem;
      border-color: var(--color-primary-500);
      border-top-style: none;
      border-right-style: none;
      top: 2px;
    }

    &:checked:after {
      background: #285651;
    }

    &:after {
      content: '';
      position: absolute;
      top: -1px;
      left: -1px;
      width: var(--space-3);
      height: var(--space-3);
      background: var(--bg-1);
      cursor: pointer;
      border-radius: var(--border-radius-0);
    }
  }
}
