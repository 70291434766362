@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700;800;900;1000&display=swap');
// @import url('https://fonts.cdnfonts.com/css/nunito');

// COMING SOON... ???
// @font-face {
//   font-family: Inter;
//   src: url('/global-assets/fonts/FontsFree-Net-CircularStd-Black.ttf'),
//     url('https://firebasestorage.googleapis.com/v0/b/dixper-abae2.appspot.com/o/Assets%2Ffonts%2FCera%20Pro%20Thin.otf?alt=media&token=b72b8c2f-3a9b-437b-b4ac-09534b7f256c');
//   font-weight: 900;
// }
// @font-face {
//   font-family: Inter;
//   src: url('/global-assets/fonts/FontsFree-Net-CircularStd-Bold.ttf'),
//     url('https://firebasestorage.googleapis.com/v0/b/dixper-abae2.appspot.com/o/Assets%2Ffonts%2FCera%20Pro%20Thin.otf?alt=media&token=b72b8c2f-3a9b-437b-b4ac-09534b7f256c');
//   font-weight: 700;
// }
// @font-face {
//   font-family: Inter;
//   src: url('/global-assets/fonts/FontsFree-Net-CircularStd-Medium.ttf'),
//     url('https://firebasestorage.googleapis.com/v0/b/dixper-abae2.appspot.com/o/Assets%2Ffonts%2FCera%20Pro%20Thin.otf?alt=media&token=b72b8c2f-3a9b-437b-b4ac-09534b7f256c');
//   font-weight: 500;
// }

@font-face {
  font-family: Halloween;
  src: url(/global-assets/fonts/halloween.ttf);
}

@font-face {
  font-family: Christmas;
  src: url(/global-assets/fonts/Lobster-Regular.ttf);
}

@font-face {
  font-family: American Captain;
  src: url(/global-assets/fonts/american-captain.ttf);
}
@font-face {
  font-family: bahnschrift;
  src: url(/global-assets/fonts/bahnschrift.ttf);
  font-weight: 900;
}

@font-face {
  font-family: Beaufort;
  src: url(/global-assets/fonts/BeaufortForLoL/halloween.ttf);
  font-weight: 900;
}

@font-face {
  font-family: Beaufort;
  src: url(/global-assets/fonts/BeaufortForLoL/BeaufortforLOL-Light.ttf);
  font-weight: 300;
}

@font-face {
  font-family: Beaufort;
  src: url(/global-assets/fonts/BeaufortForLoL/BeaufortforLOL-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: Beaufort;
  src: url(/global-assets/fonts/BeaufortForLoL/BeaufortforLOL-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: Beaufort;
  src: url(/global-assets/fonts/BeaufortForLoL/BeaufortforLOL-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: Beaufort;
  src: url(/global-assets/fonts/BeaufortForLoL/BeaufortforLOL-Heavy.ttf);
  font-weight: 900;
}

@font-face {
  font-family: Spiegel;
  src: url(/global-assets/fonts/Spiegel/Spiegel_TT_Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: Spiegel;
  src: url(/global-assets/fonts/Spiegel/Spiegel_TT_SemiBold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: Spiegel;
  src: url(/global-assets/fonts/Spiegel/Spiegel_TT_Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: LEC;
  src: url(/global-assets/fonts/Druk-Bold-Trial.otf);
  font-weight: 700;
}
