@mixin theme($theme: 'skills') {
  @if $theme == 'skills' {
    @include theme-skills();
  } @else if $theme == 'challenges' {
    @include theme-challenges();
  }
}

@mixin theme-skills() {
  :root {
    /* Color styles */
    --color-primary-100: hsla(165, 91.8%, 90.4%, 1);
    --color-primary-200: hsla(165, 91.8%, 80.8%, 1);
    --color-primary-300: hsla(166, 91.8%, 71.2%, 1);
    --color-primary-400: hsla(166, 91.8%, 61.6%, 1);
    --color-primary-500: hsla(166, 91.8%, 52%, 1);
    --color-primary-600: #1ca588;
    --color-primary-700: #21685d;
    --color-primary-800: #23544f;
    --color-primary-900: #254040;
    --color-primary-500-transparent: hsla(166, 93%, 49%, 0.25);

    --color-secondary-100: hsla(240, 1.1%, 82.9%, 1);
    --color-secondary-200: hsla(220, 1.7%, 65.7%, 1);
    --color-secondary-300: hsla(216, 2%, 48.8%, 1);
    --color-secondary-400: hsla(214, 4.3%, 31.6%, 1);
    --color-secondary-500: hsla(218, 10.8%, 14.5%, 1);
    --color-secondary-600: hsla(214, 11.9%, 11.6%, 1);
    --color-secondary-700: hsla(216, 11.1%, 8.8%, 1);
    --color-secondary-800: hsla(220, 10.3%, 5.7%, 1);
    --color-secondary-900: hsla(240, 6.7%, 2.9%, 1);

    --color-tertiary-100: hsla(240, 33.3%, 98.8%, 1);
    --color-tertiary-200: hsla(240, 16.7%, 97.6%, 1);
    --color-tertiary-300: hsla(240, 22.2%, 96.5%, 1);
    --color-tertiary-400: hsla(240, 16.7%, 95.3%, 1);
    --color-tertiary-500: hsla(240, 20%, 94.1%, 1);
    --color-tertiary-600: hsla(240, 3.2%, 75.3%, 1);
    --color-tertiary-700: hsla(240, 1.8%, 56.5%, 1);
    --color-tertiary-800: hsla(240, 1%, 37.6%, 1);
    --color-tertiary-900: hsla(240, 2.1%, 18.8%, 1);
  }
}
@mixin theme-challenges() {
  :root {
    /* Color styles */
    --color-primary-100: #ff80b3;
    --color-primary-200: #ff6699;
    --color-primary-300: #ff4d80;
    --color-primary-400: #ff3366;
    --color-primary-500: #f51451;
    --color-primary-600: #d51247;
    --color-primary-700: #b70f3d;
    --color-primary-800: #990c33;
    --color-primary-900: #7b092a;
    --color-primary-500-transparent: hsla(344, 94%, 49%, 0.25);

    --color-secondary-100: hsla(240, 1.1%, 82.9%, 1);
    --color-secondary-200: hsla(220, 1.7%, 65.7%, 1);
    --color-secondary-300: hsla(216, 2%, 48.8%, 1);
    --color-secondary-400: hsla(214, 4.3%, 31.6%, 1);
    --color-secondary-500: rgb(33, 36, 41);
    --color-secondary-600: hsla(214, 11.9%, 11.6%, 1);
    --color-secondary-700: hsla(216, 11.1%, 8.8%, 1);
    --color-secondary-800: hsla(220, 10.3%, 5.7%, 1);
    --color-secondary-900: hsla(240, 6.7%, 2.9%, 1);

    --color-tertiary-100: hsla(240, 33.3%, 98.8%, 1);
    --color-tertiary-200: hsla(240, 16.7%, 97.6%, 1);
    --color-tertiary-300: hsla(240, 22.2%, 96.5%, 1);
    --color-tertiary-400: hsla(240, 16.7%, 95.3%, 1);
    --color-tertiary-500: hsla(240, 20%, 94.1%, 1);
    --color-tertiary-600: hsla(240, 3.2%, 75.3%, 1);
    --color-tertiary-700: hsla(240, 1.8%, 56.5%, 1);
    --color-tertiary-800: hsla(240, 1%, 37.6%, 1);
    --color-tertiary-900: hsla(240, 2.1%, 18.8%, 1);
  }
}

// COMMON
:root {
  --space-navbar: 30px;

  /* Apps Colors */
  --color-challenges-500: #f51451; // CHAT GPT COLOR: #ff0046
  --color-skills-500: #f5bf14;
  --color-vision-500: #12f4e3;

  /* Role Colors */
  --color-streamer: #14f5bf;
  --color-affiliate: #00d8e6;
  --color-partner: #eb4bee;
  --color-ambassador: #f2c94c;

  /* Background Colors */
  --bg-0: #12141a;
  --bg-1: #181b21;
  --bg-2: #1d2025;
  --bg-3: #272c32;
  --bg-4: #31383e;
  --bg-5: #373d44;

  --color-bg-300: hsla(212, 10.6%, 24.1%, 1);
  --color-bg-200: hsla(212, 6.7%, 37.8%, 1);
  --color-bg-400: rgba(49, 56, 62, 0.953);
  --color-bg-500: hsla(213, 12.4%, 17.5%, 1);
  --color-bg-600: hsla(218, 10.8%, 14.5%, 1);
  --color-bg-700: hsla(218, 12.1%, 12.9%, 1);
  --color-bg-800: #18191c;
  --color-bg-900: #0a0b0c;

  /* Text Colors */
  --color-text-100: hsla(240, 33.3%, 98.8%, 1);
  --color-text-200: hsla(240, 16.7%, 97.6%, 1);
  --color-text-300: hsla(240, 22.2%, 96.5%, 1);
  --color-text-400: hsla(240, 16.7%, 95.3%, 1);
  --color-text-500: hsla(222deg 5% 94% / 1);
  --color-text-600: hsla(222deg 5% 76% / 1);
  --color-text-700: hsla(222deg 5% 62% / 1);
  --color-text-800: hsla(222deg 6% 52% / 1);
  --color-text-900: hsla(222deg 7% 30% / 1);
  --color-text-1000: hsla(0, 0%, 0%);

  /* Rarity color styles */
  --color-rarity-mythical: #ff7f4c;
  --color-rarity-legendary: #e9c41f;
  --color-rarity-epic: #ca20e2;
  --color-rarity-rare: #12cee4;
  --color-rarity-uncommon: #34a244;
  --color-rarity-common: #838a95;

  --color-beta-500: #f2b04c;

  /* Common Colors */
  --color-blue-100: hsla(184, 82.1%, 89%, 1);
  --color-blue-200: hsla(184, 82.1%, 78%, 1);
  --color-blue-300: hsla(183, 82.1%, 67.1%, 1);
  --color-blue-400: hsla(184, 82.1%, 56.1%, 1);
  --color-blue-500: hsla(184, 100%, 45.1%, 1);
  --color-blue-600: hsla(184, 100%, 36.1%, 1);
  --color-blue-700: hsla(183, 100%, 27.1%, 1);
  --color-blue-800: hsla(184, 100%, 18%, 1);
  --color-blue-900: hsla(184, 100%, 9%, 1);

  --color-yellow-100: hsla(45, 84.6%, 92.4%, 1);
  --color-yellow-200: hsla(45, 87%, 84.9%, 1);
  --color-yellow-300: hsla(45, 86.1%, 77.5%, 1);
  --color-yellow-400: #f9c944;
  --color-yellow-500: #febc05;
  --color-yellow-600: rgb(194, 161, 61);
  --color-yellow-700: hsla(45, 51.8%, 37.5%, 1);
  --color-yellow-800: hsla(45, 52.8%, 24.9%, 1);
  --color-yellow-900: hsla(45, 52.4%, 12.4%, 1);

  --color-green-100: #26c281;
  --color-green-200: #26c281;
  --color-green-300: #26c281;
  --color-green-400: #26c281;
  --color-green-500: #00dd68;
  --color-green-600: #26c281;
  --color-green-700: #26c281;
  --color-green-800: #26c281;
  --color-green-900: #26c281;

  --color-pink-100: hsla(298, 84.6%, 92.4%, 1);
  --color-pink-200: hsla(299, 82.3%, 84.5%, 1);
  --color-pink-300: hsla(299, 83.1%, 76.9%, 1);
  --color-pink-400: hsla(299, 82.3%, 69%, 1);
  --color-pink-500: hsla(299, 82.7%, 61.4%, 1);
  --color-pink-600: hsla(299, 52%, 49%, 1);
  --color-pink-700: hsla(299, 52.1%, 36.9%, 1);
  --color-pink-800: hsla(299, 52%, 24.5%, 1);
  --color-pink-900: hsla(298, 52.4%, 12.4%, 1);

  --color-red-100: hsla(0, 95%, 92.2%, 1);
  --color-red-200: hsla(359, 95%, 84.3%, 1);
  --color-red-300: hsla(0, 96.6%, 76.9%, 1);
  --color-red-400: hsla(0, 96.2%, 69%, 1);
  --color-red-500: #fb3d3e;
  --color-red-600: hsla(0, 60.8%, 49%, 1);
  --color-red-700: hsla(0, 60.6%, 36.9%, 1);
  --color-red-800: hsla(359, 61.3%, 24.3%, 1);
  --color-red-900: hsla(0, 61.3%, 12.2%, 1);

  --color-discord: #7289da;
  --color-twitch: #772ce8;
  --color-twitch-ligth: #9147ff;
  --color-youtube: #ff0000;
  --color-facebook: #3b5998;
  --color-trovo: #3cdb7d;
  --color-google: #fff;
  --color-twitter: #14171a;
  --color-instagram: #dd2a7b;
  --color-other: #c4c8c5;

  --blur-0: #1d2025c9;
  --blur-1: rgba(33, 36, 41, 0.6);

  --blue: #49b4ff;
  --dark-blue: #0085d0;
  --green: #26c281;
  --green-light: #14e066;
  --red: #ff5859;
  --yellow: #f3ca4e;
  --pink: #ef6bde;
  --orange: #ef9950;

  --gold: #eab03e;
  --silver: #8a919b;
  --bronze: #703f35;

  --highlight: rgba(255, 255, 255, 0.05);
  --highlight-hover: rgba(255, 255, 255, 0.025);
  --row-color: rgba(255, 255, 255, 0.02);

  --color-text-link: rgb(62, 166, 255);
  --color-text-link-hover: rgb(49, 140, 218);

  /* Font size styles */
  --font-size-0: 11px;
  --font-size-1: 13px;
  --font-size-2: 14px;
  --font-size-3: 16px;
  --font-size-4: 18px;
  --font-size-5: 20px;
  --font-size-6: 24px;
  --font-size-7: 30px;
  --font-size-8: 36px;
  --font-size-9: 48px;
  --font-size-10: 60px;
  --font-size-11: 72px;
  --font-size-12: 84px;
  --font-size-13: 96px;

  /* Spaces styles */
  --space-0: 4px;
  --space-1: 8px;
  --space-2: 12px;
  --space-3: 16px;
  --space-4: 24px;
  --space-5: 32px;
  --space-6: 48px;
  --space-7: 64px;
  --space-8: 96px;
  --space-9: 128px;
  --space-10: 160px;
  --space-11: 192px;

  /* Font family styles */
  --font-family-1: 'Nunito', sans-serif;
  --font-family-2: 'Inter', sans-serif;

  /* Font weight styles */
  --font-weight-1: 100;
  --font-weight-2: 200;
  --font-weight-3: 300;
  --font-weight-4: 400;
  --font-weight-5: 500;
  --font-weight-7: 700;
  --font-weight-6: 600;
  --font-weight-8: 800;
  --font-weight-9: 900;

  /* Line heigh styles */
  --font-line-height-0: 1;
  --font-line-height-1: 1.25;
  --font-line-height-2: 1.5;
  --font-line-height-3: 2;

  /* Borders Styles*/
  --border-radius-0: 4px;
  --border-radius-1: 6px;
  --border-radius-2: 8px;
  --border-radius-3: 10px;
  --border-radius-4: 15px;
  --border-radius-5: 20px;
  --border-radius-rounded: 50%;

  --border-0: var(--bg-4);
  --border-1: var(--bg-3);
  --border-2: var(--bg-5);
  --border-3: var(--bg-2);

  /* Shadows Styles */
  --shadow-0: 0 1px 20px 0 rgba(0, 0, 0, 0.1) !important;
  --shadow-1: 0 1px 20px 0 rgba(0, 0, 0, 0.1) !important;
  --shadow-2: 0 1px 20px 0 rgba(0, 0, 0, 0.1) !important;
  --collection-icon-shadow: drop-shadow(0 0 15px rgba(0, 0, 0, 0.8));

  /* Input Styles */
  --input-bg: rgba(0, 0, 0, 0.1);
  --input-border: rgba(255, 255, 255, 0.1);
  --input-border-hover: rgba(255, 255, 255, 0.2);
  --input-border-size: 1px;

  --input-success-bg: rgba(20, 245, 193, 0.1);
  --input-success-color: rgba(20, 245, 193, 1);
  --input-success-border: rgba(20, 245, 193, 0.4);
  --input-success-border-hover: rgba(20, 245, 193, 0.6);

  --input-warning-bg: rgba(242, 201, 76, 0.1);
  --input-warning-border: rgba(242, 201, 76, 0.4);
  --input-warning-border-hover: rgba(242, 201, 76, 0.6);

  --input-error-bg: rgba(251, 61, 61, 0.1);
  --input-error-border: rgba(251, 61, 61, 0.4);
  --input-error-border: rgba(251, 61, 61, 0.6);

  /* Other Styles */
  --grid-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.1), 0 6px 30px 5px rgba(0, 0, 0, 0.08), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  --grid-shadow-inverted: 0 -16px 24px 2px rgb(0 0 0 / 10%), 0 -6px 30px 5px rgb(0 0 0 / 8%), 0 -8px 10px -5px rgb(0 0 0 / 20%);

  --hover-shadow: inset 0 0 0 2px #ffffff12;
  --hover-shadow-primary: inset 0 0 0 2px rgba(20, 245, 193, 0.1);

  --modal-shadow: 0 7px 8px -4px rgb(0 0 0 / 20%), 0 13px 19px 2px rgb(0 0 0 / 14%), 0 5px 24px 4px rgb(0 0 0 / 12%);

  --panel-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.05);
  --panel-shadow-bold: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 2px rgba(255, 255, 255, 0.05);

  --text-shadow-black: 0 5px 20px rgba(0, 0, 0, 0.2), 0 15px 40px rgba(0, 0, 0, 0.1);
  --text-shadow-green: 0 5px 20px rgba(20, 245, 193, 0.2), 0 15px 40px rgba(20, 245, 193, 0.1);
  --text-shadow-black-intense: 2px 1px 1px var(--bg-0);

  --panel-shadow-green: 0 2px 30px hsla(166, 91.8%, 52%, 0.2), 0 6px 30px hsla(166, 91.8%, 52%, 0.1);
  --panel-shadow-blue: 0 2px 30px hsla(184, 100%, 45.1%, 0.2), 0 6px 30px hsla(184, 100%, 45.1%, 0.1);
  --panel-shadow-red: 0 2px 30px hsla(0, 96%, 61.2%, 0.2), 0 6px 30px hsla(0, 96%, 61.2%, 0.1);

  --paypal: hsla(204, 100%, 36%, 1);
  --paypal-gradient-fail: linear-gradient(-45deg, var(--color-red-700), #253b80);
  --paypal-gradient: linear-gradient=fail(-45deg, #253b80, #1799d5);
  --paypal-gradient-success: linear-gradient(90deg, var(--color-primary-500), #253b80);

  --launching-zone-gradient: linear-gradient(103.73deg, #14f5bf 13.73%, #00c9ff 86.66%);
  --launching-status-button: linear-gradient(219.52deg, #14f5bf 5.09%, #00c9ff 97.17%);
  --achievement-border: linear-gradient(#f03, #f03), linear-gradient(#f03, #08f), linear-gradient(#08f, #f03), linear-gradient(#f03, #08f);
}
