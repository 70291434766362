@import 'mixins.scss';

@import 'sweet-alert.scss';
@import 'media-queries.scss';
@import 'fonts.scss';

@import 'ngx-datatable.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';

@import 'ng-select.scss';
@import '~@ng-select/ng-select/themes/default.theme.css';
@import 'ngx-smart-modal.scss';

@import 'ngx-rigth-click-menu.scss';

@import 'ngx-tooltip.scss';

*:focus {
  outline: none;
}

//////////////////////////////////////////////////////////////////////
//                    MEDIAS QUERIES
//////////////////////////////////////////////////////////////////////

i-tabler {
  display: flex !important;
  align-items: center;
  justify-content: center;
  stroke-width: 1.5px !important;
}

.container-min {
  @include flex(flex, column, flex-start);
  height: 100%;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1600px !important;
  }
  .container-min {
    max-width: 1200px !important;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 100%;
  }
  .container-min {
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}

//////////////////////////////////////////////////////////////////////
//                    GLOBAL
//////////////////////////////////////////////////////////////////////

body {
  font-weight: 400;
  font-family: var(--font-family-1) !important;
}
::-moz-selection {
  /* Code for Firefox */
  color: var(--bg-0);
  background: var(--color-primary-500);
}

img {
  image-rendering: -webkit-optimize-contrast;
}

::selection {
  color: var(--bg-0);
  background: var(--color-primary-500);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-text-500);
  // margin: 0;
  // padding: 0;
}

p {
  @include text('default', 3, 400);
  margin: 0;
  &.p-small {
    @include text('default', 2, 400);
  }
}

a {
  color: var(--color-text-link);
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: var(--color-text-link-hover);
    text-decoration: underline;
  }
  &.naked {
    color: inherit;
    cursor: inherit;
    &:hover {
      color: inherit;
      text-decoration: inherit;
    }
  }
}

.ui-label,
label {
  @include flex(flex, row, flex-start, flex-start);
  @include text('highlight', 1, 600);
  margin: var(--space-1) 0;
  padding-right: var(--space-3);
  width: 180px;
  span {
    color: var(--color-text-600);
  }
  small {
    @include text(var(--color-text-700), 2, 600);
    margin-left: var(--space-1);
    line-height: var(--space-3);
  }
  dx-ui-icon-tooltip {
    margin: 0 var(--space-1) 0;
  }
  &.expanded {
    width: auto;
    margin: 0;
  }
}
.label-title {
  @include text(var(--font-size-5), 5, 900);
  text-transform: uppercase;
}

.input {
  flex: 1;
  @include input;
  &::placeholder {
    color: var(--color-text-800);
    font-size: var(--font-size-2);
    font-family: var(--font-family-1);
  }
  &.disabled {
    cursor: not-allowed;
    input {
      opacity: 0.4;
      pointer-events: none;
    }
    i {
      opacity: 0.4;
      pointer-events: none;
    }
  }

  *:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;
  }

  .btn {
    margin-right: var(--space-2);
    padding: 0;
    max-height: 30px;
    flex: 0 0 30px;
    border-radius: 100%;
    background: transparent;
    color: var(--color-text-700);
    &:hover {
      background: var(--bg-2);
    }
    i {
      margin: 0;
    }
  }

  input {
    height: 40px !important;
    padding: 0 var(--space-3);
    @include input-reset;
  }

  select {
    background: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3…c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E),
      linear-gradient(to bottom, var(--input-bg) 0%, var(--input-bg) 100%) !important;
    margin: 0 10px;
    height: 40px !important;
    cursor: pointer;
    @include input-reset;
    option {
      color: var(--color-text-900);
    }
  }
}

.btn {
  color: var(--color-text-500);
  border-radius: var(--border-radius-1);
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 40px;
  outline: none !important;
  border: none !important;
  transition: 0.15s;
  font-weight: 500;
  font-size: 14px;
  box-shadow: none !important;
  justify-content: center;
  cursor: pointer;
  font-family: var(--font-family-1);
  &.small {
    font-size: 12px;
    height: 28px;
    letter-spacing: 0.5px;
    font-weight: normal;
    i {
      font-size: 14px;
    }
  }
  &.large {
    font-size: 22px;
    height: 56px;
    padding: 0 30px;
    i {
      font-size: 18px;
    }
  }
  i {
    margin-right: 10px;
  }
  &:hover {
    outline: 0;
    color: var(--color-text-500);
    filter: brightness(1.2);
  }
  &.btn-clear {
    background: transparent;
    border: none;
    box-shadow: none;
    color: var(--color-primary-500);
  }
  &:focus {
    outline: 0;
  }
  &:disabled,
  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;
  }
  &:active {
    transform: scale(0.98);
    outline: 0;
    color: var(--color-text-500);
    background: var(--color-primary-400) !important;
  }
  &.btn-primary {
    color: var(--bg-0);
    background: var(--color-primary-500);
  }
  &.btn-secondary {
    border: 1px solid var(--color-primary-500) !important;
    background: transparent;
  }
  &.btn-tertiary {
    background-color: var(--bg-3);
  }
  &.btn-new {
    letter-spacing: 0.5px;
    border-bottom: 4px solid #a1542f !important;
    border-top: 4px solid #f7e947 !important;
    background: #ecc005 !important;
    border-radius: var(--border-radius-0) !important;
    box-shadow: 0 0 0 2px #000 !important;
    text-shadow: rgb(0, 0, 0) 2px 0px 0px, rgb(0, 0, 0) 1.75517px 0.958851px 0px, rgb(0, 0, 0) 1.0806px 1.68294px 0px,
      rgb(0, 0, 0) 0.141474px 1.99499px 0px, rgb(0, 0, 0) -0.832294px 1.81859px 0px, rgb(0, 0, 0) -1.60229px 1.19694px 0px,
      rgb(0, 0, 0) -1.97998px 0.28224px 0px, rgb(0, 0, 0) -1.87291px -0.701566px 0px, rgb(0, 0, 0) -1.30729px -1.5136px 0px,
      rgb(0, 0, 0) -0.421592px -1.95506px 0px, rgb(0, 0, 0) 0.567324px -1.91785px 0px, rgb(0, 0, 0) 1.41734px -1.41108px 0px,
      rgb(0, 0, 0) 1.92034px -0.558831px 0px;
    // our color
    border-bottom: 4px solid #a5201a !important;
    border-top: 4px solid #ff9252 !important;
    background: var(--color-primary-500) !important;
    &:hover {
      border-top: 4px solid #ff9252 !important;
      background: var(--color-primary-500) Dark !important;
      border-bottom: 0px solid var(--color-primary-500) !important;
    }
    &:active {
      transform: scale(0.95);
      outline: 0;
    }
  }
}

.checkbox {
  position: relative;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  margin: 0;
  display: flex;
  align-items: center;
  font-size: var(--font-size-2);
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .checkmark {
      background-color: #2196f3;
      &:after {
        display: block;
      }
    }
    &:checked ~ span {
      color: var(--color-text-500);
    }
  }

  .checkmark {
    @include input-panel;
    border-color: #ffffff14 !important;
    border-radius: var(--border-radius-1) !important;
    height: 24px;
    width: 24px;
    min-width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 10px;
      top: 5px;
      width: 8px;
      height: 12px;
      border: solid var(--color-text-500);
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }

  &:hover {
    input {
      & ~ .checkmark {
        background-color: #ccc;
      }
    }
  }
  &--small {
    font-size: 16px;
    .checkmark {
      position: relative;
      height: 16px;
      width: 16px;
      min-width: 16px;
      &:after {
        position: absolute;
        left: 5px;
        top: 1px;
        width: 5px;
        height: 8px;
        border-width: 0 2px 2px 0;
      }
    }
  }
  span {
    margin-left: var(--space-2);
  }
}

.data-table {
  width: 100%;
}

//////////////////////////////////////////////////////////////////////
//                    BACKGROUNDS
//////////////////////////////////////////////////////////////////////

.bg-0 {
  background: var(--bg-0) !important;
}
.bg-1 {
  background: var(--bg-1) !important;
}
.bg-2 {
  background: var(--bg-2) !important;
}
.bg-3 {
  background: var(--bg-3) !important;
}
.bg-4 {
  background: var(--bg-4) !important;
}
.bg-5 {
  background: var(--bg-5) !important;
}
.bg-red {
  background: var(--color-red-500) !important;
}
.bg-green {
  background: var(--color-primary-600) !important;
}
.bg-yellow {
  background: var(--color-yellow-500) !important;
}
.bg-blue {
  background: var(--color-blue-500) !important;
}

//////////////////////////////////////////////////////////////////////
//                    TEXT COLORS
//////////////////////////////////////////////////////////////////////

.text-primary {
  color: var(--color-primary-500) !important;
}
.text-red {
  color: var(--color-red-500) !important;
}
.text-orange {
  color: var(--color-beta-500) !important;
}
.text-black {
  color: var(--color-secondary-900) !important;
  text-shadow: none;
}
.text-green {
  color: var(--color-primary-600) !important;
}
.text-yellow {
  color: var(--color-yellow-500) !important;
}
.text-blue {
  color: var(--color-blue-500) !important;
}

// ROLES
.text-team {
  color: var(--color-primary-500) !important;
}
.text-ambassador {
  color: var(--color-ambassador) !important;
}
.text-partner {
  color: var(--color-partner) !important;
}
.text-affiliate {
  color: var(--color-affiliate) !important;
}
.text-streamer {
  color: var(--color-text-500) !important;
}

.bg-team {
  background: rgba(20, 245, 193, 0.1) !important;
}
.bg-ambassador {
  background: rgba(242, 201, 76, 0.1) !important;
}
.bg-partner {
  background: rgba(235, 75, 238, 0.1) !important;
}
.bg-affiliate {
  background: rgba(0, 216, 230, 0.1) !important;
}
.bg-streamer {
  background: rgba(255, 255, 255, 0.1) !important;
}

.text-0 {
  color: var(--color-text-500) !important;
}
.text-1 {
  color: var(--color-text-600) !important;
}
.text-2 {
  color: var(--color-text-700) !important;
}
.text-3 {
  color: var(--color-text-800) !important;
}
.text-4 {
  color: var(--text-4) !important;
}
.text-5 {
  color: var(--color-text-900) !important;
}
.text-common {
  color: var(--color-rarity-common) !important;
}
.text-uncommon {
  color: var(--color-rarity-uncommon) !important;
}
.text-rare {
  color: var(--color-rarity-rare) !important;
}
.text-epic {
  color: var(--color-rarity-epic) !important;
}
.text-legendary {
  color: var(--color-rarity-legendary) !important;
}
.text-mythical {
  color: var(--color-rarity-mythical) !important;
}

.bg-common {
  background: var(--color-rarity-common) !important;
}
.bg-uncommon {
  background: var(--color-rarity-uncommon) !important;
}
.bg-rare {
  background: var(--color-rarity-rare) !important;
}
.bg-epic {
  background: var(--color-rarity-epic) !important;
}
.bg-legendary {
  background: var(--color-rarity-legendary) !important;
}
.bg-mythical {
  background: var(--color-rarity-mythical) !important;
}

//////////////////////////////////////////////////////////////////////
//                    UTILS
//////////////////////////////////////////////////////////////////////

.divider-0 {
  width: 100%;
  height: 10px;
}
.divider-1 {
  width: 100%;
  height: 30px;
}
.divider-2 {
  width: 100%;
  height: 60px;
}
.divider-3 {
  width: 100%;
  height: 90px;
}
.divider-0-line {
  width: 100%;
  height: 1px;
  background: var(--bg-2);
  padding: 4px 0;
}
.divider-1-line {
  width: 100%;
  height: 1px;
  background: var(--bg-2);
  margin: 24px 0;
}
.divider-2-line {
  width: 100%;
  height: 1px;
  background: var(--bg-2);
  margin: 29px 0;
}
.divider-3-line {
  width: 100%;
  height: 1px;
  background: var(--bg-2);
  margin: 44px 0;
}
.divider-0-line-bold {
  width: 50px;
  height: 6px;
  margin: 4px auto;
  background: var(--bg-1);
  border-radius: var(--border-radius-0);
}
.divider-1-line-bold {
  width: 50px;
  height: 6px;
  margin: 14px auto;
  background: var(--bg-1);
  border-radius: var(--border-radius-0);
}
.divider-2-line-bold {
  width: 50px;
  height: 6px;
  margin: 29px auto;
  background: var(--bg-1);
  border-radius: var(--border-radius-0);
}
.divider-3-line-bold {
  width: 50px;
  height: 6px;
  margin: 44px auto;
  background: var(--bg-1);
  border-radius: var(--border-radius-0);
}

.tabs {
  display: flex;
  border-bottom: 2px solid var(--bg-3);
  padding: 0 14px;
  margin: 0;
  list-style: none;
  width: 100%;
  position: relative;
  border-bottom: 1px solid var(--border-0);
  &.transparent {
    border: none;
  }
  &.tabs-2 {
    li {
      flex: 1;
    }
  }
  li {
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 20px;
    cursor: pointer;
    padding: var(--space-3) var(--space-3);
    transition: 0.15s;
    font-size: var(--font-size-3);
    position: relative;
    color: var(--color-text-700);
    &:hover {
      font-weight: 500;
      color: var(--color-text-500);
    }
    &.active {
      font-weight: 500;
      color: var(--color-text-500);
      &:after {
        opacity: 1;
        width: 100%;
      }
    }
    &:after {
      content: '';
      position: absolute;
      height: 3px;
      background: var(--color-primary-500);
      width: 0%;
      border-left: 0px solid var(--bg-1);
      border-right: 0px solid var(--bg-1);
      -webkit-transition: 0.15s;
      transition: 0.15s;
      bottom: 0px;
    }
    .new {
      color: var(--color-text-500);
      background: var(--color-red-500);
      top: 7px;
      right: -10px;
      font-size: 10px;
      box-sizing: border-box;
      pointer-events: none;
      z-index: 1;
      border-image: initial;
      margin-left: 10px;
      padding: 3px 10px 2px;
      letter-spacing: 0.5px;
      border-radius: var(--border-radius-3);
    }
    i {
      margin-right: 10px;
    }
  }
  @mixin tabsMobile {
    margin: 0 !important;
    width: 100% !important;
    padding: 0 !important;
    li {
      font-size: var(--font-size-2);
      padding: var(--space-2);
    }
  }
  @include xs-sm {
    @include tabsMobile;
  }
  @include sm-md {
    @include tabsMobile;
  }
}

.panel-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--space-2);
  align-items: flex-end;
  min-height: 40px;
}

.panel {
  @include flex(flex, column, center, center);
  padding: 0;
  box-shadow: none;
  border-radius: var(--border-radius-1);
  border-radius: var(--border-radius-4);
  box-shadow: var(--panel-shadow);

  &.hover {
    cursor: pointer;
    &:hover {
      filter: brightness(1.2);
    }
  }
  &.animation {
    animation: panelAnimation 0.5s ease;
    animation-fill-mode: backwards;
    @keyframes panelAnimation {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
      to {
        opacity: 1;
        transform: translateY(0px);
      }
    }
  }
  &.panel-0 {
    background: var(--bg-0);
  }
  &.panel-1 {
    background: var(--bg-1);
  }
  &.panel-2 {
    background: var(--bg-2);
  }
  &.panel-3 {
    background: var(--bg-3);
  }
  &.panel-4 {
    background: var(--color-tertiary-500);

    * {
      color: var(--bg-0);
    }
  }
  &.panel-5 {
    background: var(--color-primary-500);
    * {
      color: var(--bg-0);
    }
  }
  &.panel-transparent {
    &.scroll {
      > .header {
        padding: 0 0 20px 0;
      }
      > .content {
        padding: 0;
        overflow-y: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar {
          width: 8px !important;
        }
        &::-webkit-scrollbar-track {
          border: 4px solid var(--bg-0) !important;
        }
        &::-webkit-scrollbar-thumb {
          width: 8px !important;
          background: var(--bg-3) !important;
          border: 4px solid transparent !important;
        }
      }
      > .footer {
        padding: 0;
      }
    }
  }
  &.scroll {
    &:not(.panel-transparent) {
      &:hover {
        > .content {
          &::-webkit-scrollbar-track {
            border: 7px solid var(--bg-2) !important;
            background: var(--bg-3) !important;
          }
          &::-webkit-scrollbar-thumb {
            background: var(--bg-4) !important;
            border: 6px solid var(--bg-2) !important;
          }
        }
      }
      > .content {
        overflow-y: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar {
          width: 18px !important;
        }
        &::-webkit-scrollbar-track {
          border: 7px solid var(--bg-2) !important;
          border-radius: var(--border-radius-3) !important;
          background: var(--bg-2) !important;
        }
        &::-webkit-scrollbar-thumb {
          background: var(--bg-2) !important;
          width: 16px !important;
          border: 6px solid var(--bg-2) !important;
          border-radius: var(--border-radius-3) !important;
        }
      }
    }
  }
  &.panel-new {
    border-radius: var(--border-radius-4);
  }
  > .header {
    width: 100%;
    padding: var(--space-4) var(--space-4) 0;
    border-radius: var(--border-radius-0);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size-6);
    font-weight: bold;
    &.no-padding {
      padding: 0;
    }
    & + .content {
      padding-top: 0 !important;
    }
  }
  > .content {
    flex: 1;
    padding: var(--space-4);
    width: 100%;
    height: 100%;
    &.no-padding {
      padding: 0;
    }
  }
  > .footer {
    width: 100%;
    padding: 0 var(--space-4) var(--space-4);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &.no-padding {
      padding: 0;
    }
    &.center {
      justify-content: center;
    }
    .btn {
      &.cancel {
        margin-right: var(--space-3);
        background: transparent;
      }
      &.save {
        min-width: 100px;
      }
    }
  }
}

.form-line {
  width: calc(100% + calc(var(--space-4) * 2));
  border-top: 1px solid var(--bg-3);
  margin: 0 0 var(--space-4);
  margin-left: calc(var(--space-4) * -1);
  align-self: center;
  position: relative;
}

.sub-label {
  margin-top: var(--space-1);
  line-height: var(--font-line-height-0);
  color: var(--color-text-600);
  font-size: var(--font-size-1);
  width: 100%;
}

.badge {
  border-radius: 100px;
  padding: var(--space-0) var(--space-2);
  background: rgba(255, 255, 255, 0.1);
  img {
    height: 14px;
    margin-right: 5px;
  }
  &.small {
    padding: 2px var(--space-1);
    font-size: 60%;
  }
}

//////////////////////////////////////////////////////////////////////
//                     NEW INPUT STYLES
//////////////////////////////////////////////////////////////////////

.form-group {
  display: flex;
  margin: 0 0 var(--space-4);
  flex: 1;
  justify-content: flex-start;
  width: 100%;
  &-column {
    flex-flow: column;
    .label,
    .ui-label,
    label {
      margin-bottom: var(--space-1);
      width: 100%;
    }
  }
  input {
    width: 100%;
  }
  &--row {
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    align-items: flex-end;
  }
}

pre {
  color: var(--color-text-500) !important;
  background: var(--bg-2);
  padding: var(--space-2);
  border-radius: var(--border-radius-3);
  font-size: var(--font-size-1) !important;
  margin: var(--space-2) 0;
}

.ellipsis {
  &:after {
    content: '...';
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    width: 0px;
    position: absolute;
    margin-left: 1px;
    animation: ellipsis steps(4, end) 2000ms infinite;
    text-align: start;
  }

  @keyframes ellipsis {
    to {
      width: 16px;
    }
  }
}
