:root {
  --skill-width: 175px;
}

.skill-grid {
  width: 100%;
  &-item {
    width: var(--skill-width);
  }
}

@mixin skills-grid-virtual-scrolling() {
  display: grid;
  grid-template-columns: repeat(auto-fill, var(--skill-width));
  grid-template-rows: repeat(auto-fill, 320px);
  grid-column-gap: var(--space-5);
  grid-row-gap: var(--space-5);
  justify-content: center;
  width: 100%;
}
