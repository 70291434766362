/* You can add global styles to this file, and also import other style files */
@import 'bootstrap/scss/bootstrap';
@import 'colors.scss';
@import 'media-queries';
@import 'components.scss';
@import 'fonts.scss';
@import 'animate.css';
@import 'skills.scss';

@include theme('skills');

@font-face {
  font-family: BebasNeue;
  src: url(assets/BebasNeue-Regular.woff);
}

@font-face {
  font-family: American Captain;
  src: url(assets/american-captain.ttf);
}

body {
  color: var(--color-text-600);
  background-color: transparent;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-family: var(--font-family-1);
}

.main-wrapper {
  height: 400px;
  width: 600px;
  position: absolute;
  perspective: 500px;
  perspective: 750px;
  display: flex;
  flex-flow: column;
  padding: 30px;
  &.lefttop {
    align-items: flex-start;
    justify-content: flex-start;
    bottom: auto;
    right: auto;
    left: 0;
    top: 0;
    .bg1 {
      background: radial-gradient(at 0% 0%, black 0%, rgba(255, 0, 0, 0) 70%);
    }
  }
  &.leftbottom {
    align-items: flex-start;
    justify-content: flex-end;
    bottom: 0;
    right: auto;
    left: 0;
    top: auto;
    .bg1 {
      background: radial-gradient(at 0% 100%, black 0%, rgba(255, 0, 0, 0) 70%);
    }
  }
  &.righttop {
    align-items: flex-end;
    justify-content: flex-start;
    bottom: auto;
    right: 0;
    left: auto;
    top: 0;
    .bg1 {
      background: radial-gradient(at 100% 0%, black 0%, rgba(255, 0, 0, 0) 70%);
    }
  }
  &.rightbottom {
    align-items: flex-end;
    justify-content: flex-end;
    bottom: 0;
    right: 0;
    left: auto;
    top: auto;
    .bg1 {
      background: radial-gradient(at 100% 100%, black 0%, rgba(255, 0, 0, 0) 70%);
    }
  }

  .bg1 {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
  }
}
