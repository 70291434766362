@mixin text($type: 'default', $size: 1, $weight: 400, $link: false) {
  @include text-type($type);
  @include text-size($size);
  @include text-weight($weight);

  @if $link == true {
    text-decoration: underline;
    cursor: pointer;
  }
}

@mixin text-type($type: 'default') {
  @if $type == 'default' {
    color: var(--color-text-600);
  } @else if $type == 'highlight' {
    color: var(--color-text-500);
  } @else if $type == 'low' {
    color: var(--color-text-800);
  } @else {
    @include text-color($type);
  }
}

@mixin text-size($size: 1) {
  @if $size == 0 {
    font-size: var(--font-size-0);
  } @else if $size == 1 {
    font-size: var(--font-size-1);
  } @else if $size == 2 {
    font-size: var(--font-size-2);
  } @else if $size == 3 {
    font-size: var(--font-size-3);
  } @else if $size == 4 {
    font-size: var(--font-size-4);
  } @else if $size == 4 {
    font-size: var(--font-size-4);
  } @else if $size == 5 {
    font-size: var(--font-size-5);
  } @else if $size == 6 {
    font-size: var(--font-size-6);
  } @else if $size == 7 {
    font-size: var(--font-size-7);
  } @else if $size == 8 {
    font-size: var(--font-size-8);
  } @else if $size == 9 {
    font-size: var(--font-size-9);
  } @else if $size == 10 {
    font-size: var(--font-size-10);
  } @else if $size == 11 {
    font-size: var(--font-size-11);
  } @else if $size == 12 {
    font-size: var(--font-size-12);
  } @else if $size == 13 {
    font-size: var(--font-size-13);
  } @else {
    font-size: $size;
  }
}

@mixin text-weight($weight: 500) {
  font-weight: $weight;
}

@mixin text-color($color: var(--color-text-600)) {
  color: $color;
}

@mixin text-shorten($numLines: 1) {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: clip;

  @supports (-webkit-line-clamp: $numLines) {
    overflow: clip;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: $numLines;
    -webkit-box-orient: vertical;
  }
}

@mixin ellipsis($numLines: 1) {
  @include text-shorten($numLines);
}
