@import 'mixins.scss';

ng-select {
  flex: 0 0 40px;
  .ng-select-container {
    height: 40px !important;
    backdrop-filter: blur(20px);
  }
  &.ng-select-disabled {
    opacity: 0.3;
    pointer-events: none;
  }
  .ng-value-label {
    color: var(--color-text-500);
  }
  .ng-value {
    height: 100%;
    display: flex;
    align-items: center;
    i {
      margin-right: var(--space-1);
    }
  }
  > div {
    color: var(--color-text-90000) !important;
    height: 40px !important;
    box-shadow: none !important;
    background-color: var(--bg-3) !important;
    border: none !important;
    border-radius: var(--border-radius-1) !important;
    cursor: pointer !important;
    @include input;
    .ng-placeholder {
      color: var(--color-text-800) !important;
      padding-top: 2px;
      font-size: var(--font-size-2) !important;
      top: auto !important;
      word-break: break-all;
      text-overflow: ellipsis;
      width: 78%;
      white-space: nowrap;
      overflow: hidden;
    }
    input {
      color: var(--color-text-500);
      cursor: text;
    }
  }
  ::-webkit-scrollbar {
    width: 5px !important;
  }
  ::-webkit-scrollbar-track {
    background: var(--bg-2);
    border-radius: var(--border-radius-3);
  }
  ::-webkit-scrollbar-thumb {
    background: var(--bg-4);
    border-radius: var(--border-radius-3);
  }
}
.ng-dropdown-panel.ng-select-bottom,
.ng-dropdown-panel.ng-select-top {
  background-color: var(--bg-3) !important;
  border: none !important;
  padding: var(--space-1) 0 !important;
  margin-top: 0 !important;
  border-radius: 0 0 var(--border-radius-1) var(--border-radius-1) !important;
  color: var(--color-text-90000) !important;
  .ng-option {
    background-color: var(--bg-3) !important;
    color: var(--text-600) !important;
    &:hover {
      background-color: var(--bg-4) !important;
    }
  }
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: var(--bg-4) !important;
  color: var(--color-text-90000) !important;
}
