@import 'flex-box-mixins.scss';

tooltip-content {
  display: contents;
  .fade:not(.show) {
    opacity: 1;
  }
  .tooltip {
    pointer-events: none;
    position: absolute;
    opacity: 1 !important;
    animation-duration: 0.15s !important;
    animation-timing-function: ease !important;
    animation-fill-mode: both !important;
    &.bottom {
      animation-name: tooltipAnimationTop;
      .tooltip-inner:before {
        top: -5px !important;
        border-radius: var(--border-radius-0) 0 0;
      }
    }
    &.top {
      animation-name: tooltipAnimationBottom;
      .tooltip-inner:before {
        bottom: -5px !important;
        border-radius: 0 0 var(--border-radius-0) 0;
      }
    }
    &.left {
      animation-name: tooltipAnimationRight;

      .tooltip-inner:before {
        top: calc(50% - 8px) !important;
        left: auto;
        right: -5px;
        border-radius: 0 var(--border-radius-0) 0 0;
      }
    }
    &.right {
      animation-name: tooltipAnimationLeft;
      .tooltip-inner:before {
        top: calc(50% - 8px) !important;
        left: -5px;
        border-radius: 0 0 0 var(--border-radius-0);
      }
    }
    .tooltip-inner {
      max-width: initial;
      background: #000;
      color: var(--color-text-500);
      padding: var(--space-3) var(--space-4);
      border-radius: var(--border-radius-2);
      box-shadow: var(--panel-shadow);
      z-index: 20;
      position: relative;
      text-overflow: ellipsis;
      max-width: 900px;
      font-weight: 500;
      line-height: var(--font-line-height-1);
      font-size: var(--font-size-1);
      text-transform: initial;
      text-align: left;
      @include flex(flex, row, center, center);
      &:before {
        height: 20px;
        width: 20px;
        background: #000;
        left: calc(50% - 7px);
        border-left: none;
        border-top: none;
        transform: rotateZ(45deg);
        position: absolute;
        z-index: -2;
        content: ' ';
      }
      table {
        color: var(--color-text-500);
      }
    }
  }

  @keyframes tooltipAnimationTop {
    from {
      transform: translateY(-10px);
      opacity: 0;
    }

    to {
      transform: translateY(15px);
      opacity: 1;
    }
  }
  @keyframes tooltipAnimationBottom {
    from {
      transform: translateY(10px);
      opacity: 0;
    }

    to {
      transform: translateY(-15px);
      opacity: 1;
    }
  }
  @keyframes tooltipAnimationLeft {
    from {
      transform: translateX(-20px);
      opacity: 0;
    }

    to {
      transform: translateX(5px);
      opacity: 1;
    }
  }
  @keyframes tooltipAnimationRight {
    from {
      transform: translateX(20px);
      opacity: 0;
    }

    to {
      transform: translateX(-5px);
      opacity: 1;
    }
  }
}
